export const homeObjTwo = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: "Get in touch with me",
  headline: "",
  description: "",
  buttonLabel: "Start Now",
  imgStart: false,
  img: "/images/contact.svg",
  alt: "car",
  primary: false,
  darkText: true,
};
